import { useEffect, useState } from 'react'
import { apiGet, apiPost } from 'mp-structure/libs/api'
import useForm from 'mp-common/components/form/hooks/useForm'
import { useToast } from 'mp-common/hooks/useToast'
import useTranslation from 'next-translate/useTranslation'
import useSession from 'mp-auth/hooks/useSession'
import useMixPanel from "mp-common/hooks/useMixPanel";

const useUserProfile = () => {
  const { t } = useTranslation()
  const [isEditModeOn, setIsEditModeOn] = useState(false)
  const [loadingUser, setLoadingUser] = useState<boolean>()
  const [user, setUser] = useState()
  const { form, setForm, onChange, errors, setErrors } = useForm({})
  const { toastSuccess, toastError } = useToast()
  const { fetchUser } = useSession()
  const inputProps = { form, onChange, errors }
  const fetchUserProfile = async (force = false) => {
    if (force === false && sessionStorage.getItem('profile_cache_')) {
      const cachedUser = JSON.parse(sessionStorage.getItem('profile_cache_'))
      setUser(cachedUser)
      setForm(cachedUser)
      return
    }

    setLoadingUser(true)
    const res = await apiGet('/user/profile')
    if (res?.data) {
      const _user = {
        ...res?.data,
        spokenLanguages: res?.data?.spokenLanguages || []
      }
      setUser(_user)
      setForm(_user)
      sessionStorage.setItem('profile_cache_', JSON.stringify(_user))
    }
    setLoadingUser(false)
  }

  const updateUserProfile = async (onSuccess) => {
    setLoadingUser(true)
    const { avatarUrl, avatarThumbnailUrl, spokenLanguages, ...rest } = form
    const formData = new FormData()
    Object.keys(rest)?.forEach((key) => {
      if (!Array.isArray(rest[key])) {
        formData.append(key, rest[key])
      }
    })

    if (spokenLanguages?.length > 0) {
      spokenLanguages?.forEach((lang, index) => {
        formData?.append(`spokenLanguages[${index}]`, lang)
      })
    }

    const res = await apiPost('/user/profile/update', formData, {}, true)
    if (res?.data) {
      toastSuccess(t('common:edit_success'))
      onSuccess()
      await fetchUserProfile(true)
      await fetchUser()
    } else if (res?.errors) {
      setErrors(res?.errors)
    } else if (res?.error) {
      toastError(res.error)
    }

    setLoadingUser(false)
  }

  const updateUserPassword = async () => {
    setLoadingUser(true)
    const res = await apiPost('user/password/update', {
      currentPassword: form?.currentPassword,
      newPassword: form?.password
    })

    if (res?.response?.data?.message) {
      toastError(res?.response?.data?.message)
    } else if (res?.error) {
      toastError(res.error)
    } else if (res?.data?.success) {
      toastSuccess(t('profile:password_changed'))
      setForm({})
    }

    setLoadingUser(false)
  }

  const cancel = () => {
    setIsEditModeOn(false)
    setForm(user)
  }

  useEffect(() => {
    fetchUserProfile()
  }, [])

  useMixPanel({user})
  return {
    user: form,
    fetchUserProfile,
    loadingUser,
    updateUserProfile,
    inputProps,
    updateUserPassword,
    form,
    isEditModeOn,
    setIsEditModeOn,
    cancel
  }
}

export default useUserProfile
