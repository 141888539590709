import React from 'react'
import dynamic from 'next/dynamic'
import { dateToDM } from 'mp-structure/libs/utils'
import get from 'lodash/get'
import useTranslation from 'next-translate/useTranslation'
import GImage from '../../../../common/components/form/elements/GImage'

const EllipsisToolTip = dynamic(() => import('ellipsis-tooltip-react-chan'), { ssr: false })

const ColumnDelivery = ({ delivery, selectAndShowQuickView, flagURL = null }) => {
  const { t } = useTranslation()

  if (!delivery) {
    return (
      <td className="tm-dest-detail border-right">
        <div
          onClick={selectAndShowQuickView}
          className="tm-dest-name cursor-pointer"
        >
          <EllipsisToolTip>{t('freight:any_location')}</EllipsisToolTip>
        </div>
      </td>
    )
  }

  return (
    <td className="tm-dest-detail border-right">
      <div className="tm-dest-grid align-center">
        {flagURL && get(delivery, 'location.countryCode') && (
          <GImage
            src={flagURL + get(delivery, 'location.countryCode') + '.png'}
            className="tm-table-img img-flag"
            alt=""
            width={24}
            height={24}
          />
        )}
        <div className="overflow-hidden">
          <div
            onClick={selectAndShowQuickView}
            className="tm-dest-name cursor-pointer"
          >
            <EllipsisToolTip>{delivery?.location?.label}</EllipsisToolTip>
          </div>
          {delivery?.firstDate
            ? (
              <div className="tm-dest-date">
                <GImage
                  src="icon-calender.svg"
                  className="tm-table-img"
                  width={16}
                  height={16}
                />
                {dateToDM(delivery?.firstDate)}-{dateToDM(delivery?.lastDate)}
              </div>
            ) : null}
        </div>
      </div>
    </td>
  )
}

export default ColumnDelivery
