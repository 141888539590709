import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { GHref } from 'mp-common/components/form/elements'
import Link from "next/link";

interface Props {
    page: string
}

function Navbar(props: Props) {
  const { t } = useTranslation()

  return (
    <nav className="tm-header-nav">
      <ul>
        <li>
          <Link href="/fex/freight-board" className={props.page === 'freight' ? 'active' : ''}>{t('navbar:freight')}</Link>
        </li>
        <li>
          <Link href="/fex/tender-board" className={props.page === 'tender' ? 'active' : ''}>{t('navbar:mini_tender')}</Link>
        </li>
        <li>
          <Link href="/fex/truck-board" className={props.page === 'truck' ? 'active' : ''}>{t('navbar:truck')}</Link>
        </li>
        {/* <li> */}
        {/*  <GHref href="/fex/discover" className={props.page === 'discover' ? 'active' : ''}>{t('navbar:discover')}</GHref> */}
        {/* </li> */}
        {/* <li> */}
        {/*  <GHref href="/fex/connections" className={props.page === 'connections' ? 'active' : ''}>{t('navbar:connections')}</GHref> */}
        {/* </li> */}
        {/* <li> */}
        {/*  <GHref href="/fex/channels" className={props.page === 'channels' ? 'active' : ''}>{t('navbar:channels')}</GHref> */}
        {/* </li> */}
        {/* <li> */}
        {/*  <GHref href="/fex/your-lists" className={props.page === 'your-lists' ? 'active' : ''}>{t('navbar:your_lists')}</GHref> */}
        {/* </li> */}
        {/* <li> */}
        {/*  <GHref href="/fex/settings" className={props.page === 'settings' ? 'active' : ''}>{t('navbar:settings')}</GHref> */}
        {/* </li> */}
      </ul>
    </nav>
  )
}

export default Navbar
