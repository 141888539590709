import useComponent from 'mp-common/hooks/useComponent'
import AuthBoundary from 'mp-structure/layouts/views/AuthBoundary'
import GeneralLayoutMeta from 'mp-structure/layouts/views/GeneralLayoutMeta'
import Error500Boundary from 'mp-structure/layouts/views/error/Error500Boundary'
import LayoutModals from 'mp-structure/layouts/views/LayoutModals'
import GeneralLayoutHeader from 'mp-structure/layouts/components/header/GeneralLayoutHeader'

type Props = {
  children: JSX.Element | JSX.Element[]
}

function GeneralLayout(props:Props) {
  const { children } = props

  const header = useComponent(
    children,
    {
      Component: GeneralLayoutHeader,
      displayName: GeneralLayoutHeader.displayName
    }
  )

  const meta = useComponent(
    children,
    {
      Component: GeneralLayoutMeta,
      displayName: GeneralLayoutMeta.displayName
    }
  )

  return (
    <AuthBoundary>
      <Error500Boundary>
        {header}
        {meta}
        {children}
        <LayoutModals />
      </Error500Boundary>
    </AuthBoundary>
  )
}

export default GeneralLayout
