import { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { isEmpty, forEach, isString, get, isNil } from 'lodash'
import useUpdateQueryParams from 'mp-common/hooks/useUpdateQueryParams'
import { parseDateForQuery, removeNullArrayKeys } from 'mp-structure/libs/utils'
import { TenderBoardRequestType } from 'mp-tender/types/TenderBoardTypes'
import { defaultPagination } from 'mp-structure/libs/constants'

export const defaultFreightBoardFilters = {
  page: 1,
  perPage: 30,
  radiusFrom: 200,
  radiusTo: 200
}
const useFreightBoardFilters = () => {
  const router = useRouter()
  const { query } = useRouter()
  const { getCurrentFilters } = useUpdateQueryParams()
  const [isResetActive, setIsResetActive] = useState(false)
  const [pagination, setPagination] = useState(defaultPagination)

  const manipulateDate = useCallback((_form, dateKey) => {
    const tempForm = _form
    const value = get(tempForm, dateKey)
    if (value === null || get(tempForm, dateKey + '.length') === 0) {
      delete tempForm[dateKey]
    } else if (value) {
      if (isString(value)) {
        tempForm[dateKey] = parseDateForQuery(value)
      }
    } else if (get(query, dateKey)) {
      tempForm[dateKey] = parseDateForQuery(get(query, dateKey))
    }
    return tempForm
  }, [query])

  const queryForm = useMemo(() => {
    if (isResetActive) {
      return { page: 1 }
    }
    let _form = { ...defaultFreightBoardFilters } as TenderBoardRequestType
    if (!isEmpty(query)) {
      if (isNil(_form?.companyName) && query?.companyName) {
        _form['companyName'] = String(query.companyName)
      }
      if (isNil(_form?.contactName) && query?.contactName) {
        _form['contactName'] = String(query.contactName)
      }
      if (isNil(_form?.freightType) && query?.freightType) {
        _form['freightType'] = String(query.freightType)
      }
      if (isNil(_form?.truckType) && query?.truckType) {
        _form['truckType'] = String(query.truckType)
      }
      if (isNil(_form?.periodFreightInterval) && query?.periodFreightInterval) {
        _form['periodFreightInterval'] = String(query.periodFreightInterval)
      }
      if (isNil(_form?.freightCount) && query?.freightCount) {
        _form['freightCount'] = Number(query.freightCount)
      }
      if (isNil(_form?.contactPersonName) && query?.contactPersonName) {
        _form['contactPersonName'] = String(query.contactPersonName)
      }
      if (query?.pickupLocation) {
        _form['pickupLocation'] = JSON.parse(query.pickupLocation as string)
      }
      if (query?.deliveryLocation) {
        _form['deliveryLocation'] = JSON.parse(query.deliveryLocation as string)
      }
      if (query?.locationFrom) {
        _form['locationFrom'] = JSON.parse(query.locationFrom as string)
      }
      if (query?.locationsFrom) {
        _form['locationsFrom'] = JSON.parse(query.locationsFrom as string)
      }
      if (query?.locationTo) {
        _form['locationTo'] = JSON.parse(query.locationTo as string)
      }
      if (query?.locationsTo) {
        _form['locationsTo'] = JSON.parse(query.locationsTo as string)
      }
      if (query?.radiusFrom) {
        _form['radiusFrom'] = Number(query.radiusFrom)
      }
      if (query?.radiusTo) {
        _form['radiusTo'] = Number(query.radiusTo)
      }
      if (query?.pickupRadius) {
        _form['pickupRadius'] = Number(query.pickupRadius)
      }
      if (query?.deliveryRadius) {
        _form['deliveryRadius'] = Number(query.deliveryRadius)
      }
      if (query?.specialRequirements) {
        _form['specialRequirements'] = JSON.parse(query.specialRequirements as string)
      }
      if (query?.truckTypeId) {
        _form['truckTypeId'] = String(query.truckTypeId)
      }
      if (query?.isAdr) {
        _form['isAdr'] = query.isAdr === 'true'
      }
      if (query?.isTwoDrivers) {
        _form['isTwoDrivers'] = query.isTwoDrivers === 'true'
      }
      if (query?.isTempControlled) {
        _form['isTempControlled'] = query.isTempControlled === 'true'
      }
      if (query?.targetPrice) {
        _form['targetPrice'] = Number(query.targetPrice.toString().trim())
      }
      if (query?.freightTargetPrice) {
        _form['freightTargetPrice'] = Number(query.freightTargetPrice.toString().trim())
      }
      if (query?.shipmentType) {
        _form['shipmentType'] = Number(query.shipmentType.toString().trim())
      }

      if (query?.orderBy) {
        _form['orderBy'] = JSON.parse(query.orderBy as string)
      }
    }
    // Dates
    _form = manipulateDate(_form, 'publicationDate')
    _form = manipulateDate(_form, 'publicationDateRange')
    _form = manipulateDate(_form, 'truckDate')
    _form = manipulateDate(_form, 'offerDeadline')
    _form = manipulateDate(_form, 'pickupDateRange')
    _form = manipulateDate(_form, 'loadingDate')
    _form = manipulateDate(_form, 'unloadingDate')
    _form = manipulateDate(_form, 'shipmentPeriod')
    // Remove null values
    _form = removeNullArrayKeys(_form)
    return _form
  }, [isResetActive, JSON.stringify(query)])

  // useEffect(() => {
  //   setPagination(prevState => ({ ...prevState, page: Number(query.page) }))
  // }, [queryForm.page])

  const resetFilters = () => {
    setIsResetActive(true)
    router?.replace({ pathname: router.pathname, search: '' })
    setTimeout(() => setIsResetActive(false), 150)
  }

  const applyFilter = (_filters = {}, isResetAction = false) => {
    if (isResetAction) {
      return resetFilters()
    }
    const params = new URLSearchParams()
    let _form : Record<string, any> = { ..._filters }
    _form = removeNullArrayKeys(_form)
    forEach(Object.keys(_form), k => {
      const v = _form[k]
      if (v && v !== 'false') {
        if (typeof v === 'object') {
          params.append(k, JSON.stringify(v))
        } else if (Array.isArray(v)) {
          params.append(k, encodeURIComponent(JSON.stringify(v)))
        } else {
          params.append(k, v as string)
        }
      }
    })

    router.replace({ pathname: router.pathname, search: params.toString() })
  }

  return {
    form: queryForm,
    applyFilter,
    resetFilters: () => applyFilter({}, true),
    getCurrentFilters,
    pagination,
    setPagination
  }
}

export default useFreightBoardFilters
