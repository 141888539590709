import { HEADER_FREIGHT_TYPE } from 'mp-common/hooks/useBoardTable'
import { get } from 'lodash'
import useColumnPresentation from 'mp-common/hooks/useColumnPresentation'
import { useMemo } from 'react'

const ColumnFreightType = ({ header, row, name }) => {
  const { columns } = header || {}
  const { getVisibleFields } = useColumnPresentation()

  const visibleFields = useMemo(() => getVisibleFields(columns), [JSON.stringify(columns)])

  if (!header?.visible) {
    return null
  }

  return (
    <>
      {visibleFields?.freight_type
        ? (
          <div className="tm-dest-name mt-4">
            {get(row, name) || '-'}
          </div>
        )
        : '-'}
    </>
  )
}

export default ColumnFreightType
