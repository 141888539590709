import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import useSession from 'mp-auth/hooks/useSession'

type Props = {
  children: React.ReactNode | React.ReactNode[],
}
const AuthBoundary = (props: Props) => {
  const { user } = useSession()
  const router = useRouter()
  const { children } = props

  useEffect(() => {
    if (!user) {
      router.push('/login')
    }
  }, [user])

  // if (!user) {
  //   return (
  //     <div className="mp-loading full">
  //       <GLoading size={52} />
  //     </div>
  //   )
  // }

  return (
    <>
      {children}
    </>
  )
}

export default AuthBoundary
