import { FreightType } from 'mp-freight/types/FreightType'
import { useState } from 'react'
import { HeaderType } from 'mp-common/components/table/TableHeader'

export const HEADER_PUBLICATION_DATE = 'publication_date'
export const HEADER_MINI_TENDER_INFO = 'mini_tender_info'
export const HEADER_OFFER_DEADLINE = 'offer_deadline'
export const HEADER_FREIGHT_TYPE = 'freight_type'
export const HEADER_PRICE = 'price'
export const HEADER_FREIGHT_INFORMATION = 'freight_information'
export const HEADER_TRUCK_INFORMATION = 'truck_information'
export const HEADER_SPECIAL_REQS = 'special_reqs'

const useBoardTable = () => {
  const freightInformationHeaders = [
    {
      category: HEADER_FREIGHT_INFORMATION,
      title: 'table:freight_information',
      draggable: true,
      scrollable: true,
      visible: true,
      columns: [
        { title: 'table:type_of_goods', sortable: false, visible: true, key: 'good_type', value: 'goodType.value', options: 'options:' },
        { title: 'table:weight', sortable: false, visible: true, key: 'weight', value: 'freightInfo.weight', unit: 'weight_suffix' },
        { title: 'table:length', sortable: false, visible: true, key: 'length', value: 'freightInfo.length', unit: 'length_suffix' },
        { title: 'table:volume', sortable: false, visible: true, key: 'volume', value: 'freightInfo.volume', unit: 'volume_suffix' }
      ]
    }
  ]
  const truckInformationHeadersMulti = [
    {
      category: HEADER_TRUCK_INFORMATION,
      title: 'table:truck_information',
      draggable: true,
      scrollable: true,
      visible: true,
      columns: [
        { title: 'table:truck_type', sortable: false, visible: true, key: 'truck_type', value: 'truckTypes' }
      ]
    }
  ]
  const truckInformationHeaders = [
    {
      category: HEADER_TRUCK_INFORMATION,
      title: 'table:truck_information',
      draggable: true,
      scrollable: true,
      visible: true,
      columns: [
        { title: 'table:truck_type', sortable: false, visible: true, key: 'truck_type', value: 'truckType.value' }
      ]
    }
  ]
  const specialRequirementsHeaders = [
    {
      category: HEADER_SPECIAL_REQS,
      title: 'table:special_requests',
      draggable: true,
      scrollable: true,
      visible: true,
      columns: [
        { title: 'table:adr', sortable: false, visible: true, key: 'adr', value: 'truckProps.adr', isBoolean: true },
        { title: 'table:value_of_goods', sortable: false, visible: true, key: 'value_of_goods', value: 'load.value_of_goods' },
        { title: 'table:temperature_control', sortable: false, visible: true, key: 'temperature_control', value: 'truckProps.temperatureControl', isBoolean: true },
        { title: 'table:two_drivers', sortable: false, visible: true, key: 'two_drivers', value: 'truckProps.requiresTwoDrivers', isBoolean: true },
        { title: 'table:pallets_exchange', sortable: false, visible: true, key: 'pallets_exchange', value: 'load.pallets_exchange' }
      ]
    }
  ]
  const freightTypeHeader = {
    category: HEADER_FREIGHT_TYPE,
    title: 'table:freight_type',
    draggable: true,
    scrollable: true,
    visible: true,
    columns: [
      { title: 'table:freight_type', sortable: false, visible: true, key: 'freight_type', value: 'shipmentType.value' }
    ]
  }
  const publicationDateHeader = {
    category: HEADER_PUBLICATION_DATE,
    title: 'table:publication_date',
    draggable: true,
    scrollable: true,
    visible: true,
    columns: [
      { title: 'table:publication_date', sortable: false, visible: true, key: 'publication_date', value: 'publicationDate', isDate: true }
    ]
  }

  const [freightBoardHeaders, setFreightBoardHeaders] = useState<HeaderType[]>([
    {
      category: 'constants',
      scrollable: false,
      draggable: false,
      visible: true,
      columns: [
        { title: 'table:loading', sortable: true, visible: true, key: 'pickupDate' },
        { title: 'table:total_distance', icon: 'icon-km.svg', sortable: true, className: 'col-km', visible: true, key: 'distance' },
        { title: 'table:total_stops', icon: 'icon-stops.svg', sortable: true, className: 'col-km', visible: true, key: 'stopCount' },
        { title: 'table:unloading', sortable: true, visible: true, key: 'deliveryDate' },
        { title: 'table:company', sortable: true, visible: true, key: 'company' },
        { title: 'table:price', className: 'no-border', sortable: true, visible: true, key: 'price' }
      ]
    },
    publicationDateHeader,
    freightTypeHeader,
    ...freightInformationHeaders,
    ...truckInformationHeadersMulti,
    ...specialRequirementsHeaders
  ])

  const [tenderBoardHeaders, setTenderBoardHeaders] = useState<HeaderType[]>([
    {
      category: 'constants',
      scrollable: false,
      draggable: false,
      visible: true,
      columns: [
        { title: 'table:loading', sortable: false, visible: true, key: 'pickupDate' },
        { title: 'table:total_distance', icon: 'icon-km.svg', sortable: true, className: 'col-km', visible: true, key: 'distance' },
        { title: 'table:total_stops', icon: 'icon-stops.svg', sortable: true, className: 'col-km', visible: true, key: 'stopCount' },
        { title: 'table:unloading', sortable: false, visible: true, key: 'deliveryDate' },
        { title: 'table:company', sortable: true, visible: true, key: 'company' },
        { title: 'table:price', className: 'no-border', sortable: true, visible: true, key: 'price' }
      ]
    },
    {
      category: HEADER_MINI_TENDER_INFO,
      title: 'table:mini_tender_information',
      draggable: true,
      scrollable: true,
      visible: true,
      columns: [
        { title: 'table:mini_tender_information', sortable: false, visible: true, key: 'mini_tender_info', value: 'period.count' }
      ]
    },
    {
      category: HEADER_OFFER_DEADLINE,
      title: 'table:deadline_of_offers',
      draggable: true,
      scrollable: true,
      visible: true,
      columns: [
        { title: 'table:deadline_of_offers', sortable: false, visible: true, key: 'deadline_of_offers', value: 'period.offerDeadline', isDate: true }
      ]
    },
    freightTypeHeader,
    {
      category: HEADER_FREIGHT_INFORMATION,
      title: 'table:freight_information',
      draggable: true,
      scrollable: true,
      visible: true,
      columns: [
        { title: 'table:type_of_goods', sortable: false, visible: true, key: 'good_type', value: 'goodType.value', options: 'options:' },
        { title: 'table:weight', sortable: false, visible: true, key: 'weight', value: 'freightInfo.weight', unit: 'weight_suffix' },
        { title: 'table:length', sortable: false, visible: true, key: 'length', value: 'freightInfo.length', unit: 'length_suffix' },
        { title: 'table:volume', sortable: false, visible: true, key: 'volume', value: 'freightInfo.volume', unit: 'volume_suffix' }
      ]
    },
    ...truckInformationHeaders,
    {
      category: HEADER_SPECIAL_REQS,
      title: 'table:special_requests',
      draggable: true,
      scrollable: true,
      visible: true,
      columns: [
        { title: 'table:adr', sortable: false, visible: true, key: 'adr', value: 'truckProps.adr', isBoolean: true },
        { title: 'table:value_of_goods', sortable: false, visible: true, key: 'value_of_goods', value: 'truckProps.goodValue' },
        { title: 'table:temperature_control', sortable: false, visible: true, key: 'temperature_control', value: 'truckProps.temperatureControl', isBoolean: true },
        { title: 'table:two_drivers', sortable: false, visible: true, key: 'two_drivers', value: 'truckProps.requiresTwoDrivers', isBoolean: true },
        { title: 'table:pallets_exchange', sortable: false, visible: true, key: 'pallets_exchange', value: 'isPalletsExchange' }
      ]
    },
    {
      category: HEADER_PUBLICATION_DATE,
      title: 'table:publication_date',
      draggable: true,
      scrollable: true,
      visible: true,
      columns: [
        { title: 'table:publication_date', sortable: false, visible: true, key: 'publication_date', value: 'publicationDate', isDate: true }
      ]
    }
  ])

  const showScroll = (headers) => !!(headers.find((header) => header?.scrollable && header?.columns?.find((column) => column.visible)))

  return {
    freightBoardHeaders,
    setFreightBoardHeaders,
    tenderBoardHeaders,
    setTenderBoardHeaders,
    showScroll
  }
}

export default useBoardTable
