import React, { useCallback, useEffect, useState } from 'react'
import { HeaderType } from 'modules/common/components/table/TableHeader'
import useTranslation from 'next-translate/useTranslation'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import GImage from 'modules/common/components/form/elements/GImage'
import GHref from 'mp-common/components/form/elements/GHref'
import ViewOptionHeader from 'mp-freight/components/ViewOptionHeader'
import useViewOptions from 'mp-common/hooks/useViewOptions'
import { parseViewOptions } from 'mp-structure/libs/utils'

interface Props {
  setHeaders: (props: any) => void
  setShowViewsOptions: (props: any) => void,
  showViewOptions: boolean,
  headers: HeaderType[]
  boardName: string
}

function ViewOptions(props: Props) {
  const { headers, setHeaders, boardName, showViewOptions, setShowViewsOptions } = props
  const { t } = useTranslation()
  const { updateViewOptions, getViewOptions } = useViewOptions()
  const [customHeaders, setCustomHeaders] = useState<HeaderType[]>([...headers])

  const toggleColumnView = useCallback((column: any) => {
    const _headers = [...customHeaders]
    _headers.forEach((x) => {
      x.columns.forEach((y) => {
        if (y.key === column.key) {
          y.visible = !y.visible
        }
      })
    })

    setCustomHeaders(_headers)
  }, [customHeaders])

  const toggleGroupView = useCallback((header: any) => {
    const _headers = customHeaders.map((_header) => {
      if (_header.category === header.category) {
        _header.visible = !_header.visible
        if (_header.visible) {
          _header.columns.forEach((_column) => {
            _column.visible = true
          })
        } else {
          _header.columns.forEach((_column) => {
            _column.visible = false
          })
        }
      }
      return _header
    })
    setCustomHeaders(_headers)
  }, [customHeaders])

  const discard = () => {
    setCustomHeaders(headers)
    setShowViewsOptions(false)
  }

  const confirm = useCallback(() => {
    setHeaders(customHeaders)
    setShowViewsOptions(false)
    const items = customHeaders.map(item => ({
      category: item.category,
      visible: item.visible,
      columns: item.columns.map(col => ({
        visible: col.visible,
        key: col.key
      }))
    }))

    updateViewOptions(JSON.stringify(items), boardName)
    localStorage.setItem('viewOptions_' + boardName, JSON.stringify(items))
  }, [customHeaders, boardName])

  const onDragEnd = (result: any) => {
    const { source, destination } = result
    if (destination && destination.index !== source.index) {
      const reorderedItems = Array.from(customHeaders) || []
      const [movedItem] = reorderedItems.splice(source.index, 1)
      if (movedItem) {
        reorderedItems.splice(destination.index, 0, movedItem)
      }
      setCustomHeaders(reorderedItems)
      return result
    }
  }

  useEffect(() => {
    const _viewOptions = localStorage.getItem('viewOptions_' + boardName)
    if (_viewOptions) {
      setCustomHeaders(parseViewOptions(headers, JSON.parse(_viewOptions)))
    } else {
      const fetchData = async () => {
        const response = await getViewOptions(boardName)

        if (response) {
          setHeaders(parseViewOptions(headers, response) || '')
          setCustomHeaders(parseViewOptions(customHeaders, response) || '')
        }
      }
      fetchData()
    }
  }, [boardName])

  useEffect(() => {
    if (headers) {
      setCustomHeaders([...headers])
    }
  }, [headers])

  return (
    showViewOptions && (
      <aside className="tm-right-sidebar tm-transactions-settings-sidebar opened">
        <GHref
          className="tm-close-sidebar"
          onClick={() => setShowViewsOptions(false)}
        />
        <header>
          <GImage src="icon-settings.svg" alt="Customize Your View" />
          <span>{t('leads:customize_your_view')}</span>
        </header>
        <div className="tm-right-sidebar-content tm-custom-scroll">
          <div className="tm-transaction-fields tm-mandatory-fields">
            {customHeaders.map((header) => header.category === 'constants' && header.columns.map((column, index) => (
              <div className="tm-transaction-field-row" key={'constants_' + column.title}>
                <div className="col-number">{index + 1}</div>
                <div className="col-name">{t(column.title)}</div>
              </div>
            )))}
          </div>
          <div className="tm-transaction-fields tm-mandatory-fields-extra">
            {customHeaders.map((header) => header.category === 'visibles' && header.columns.map((column, index2) => (
              <div className={column.visible ? 'tm-transaction-field-row' : 'tm-transaction-field-row closed-row'} key={'viewOptions_' + column.key}>
                <div className="col-number">{index2 + 6}</div>
                <div className="col-name">{t(column.title)}</div>
                <GHref onClick={() => toggleColumnView(column)} className="col-show-hide">
                  {column.visible
                    ? <GImage src="icon-eye.svg" alt="" />
                    : <GImage src="icon-eye-closed.svg" alt="" />}
                </GHref>
              </div>
            )))}
          </div>
          <div className="text-14 mb-10 fw-600 text-uppercase ls-1">{t('table:scrollable_sections')}</div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="categoryList">
              {(provided: any) => (
                <div
                  {...provided?.droppableProps}
                  ref={provided?.innerRef}
                  className="tm-transaction-fields tm-scrollable-fields"
                >
                  {customHeaders.map((header, index3) => !['visibles', 'constants'].includes(header.category) && (
                    <Draggable
                      key={header?.category}
                      draggableId={header?.category}
                      index={index3}
                    >
                      {(provided: any) => (
                        <div
                          ref={provided?.innerRef}
                          {...provided?.draggableProps}
                          {...provided?.dragHandleProps}
                          className={header?.visible ? 'tm-transaction-field-row' : 'tm-transaction-field-row closed-row'}
                          key={header?.title}
                        >
                          <div className="col-number">{index3 + 8}</div>
                          <div className="col-name">
                            <div className="tm-drag-icon">
                              <GImage src="icon-drag.svg" alt="Drag & Drop" />
                            </div>
                            <span>{t(header?.title)}</span>
                            <div className="hr" />
                            <div className="options">
                              {header?.columns?.map((column) => (
                                <ViewOptionHeader column={column} toggleColumnView={toggleColumnView} key={'column_' + column.key} />
                              ))}
                            </div>
                          </div>
                          <GHref onClick={() => toggleGroupView(header)} className="col-show-hide">
                            {header.visible
                              ? <GImage src="icon-eye.svg" alt="" />
                              : <GImage src="icon-eye-closed.svg" alt="" />}
                          </GHref>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="tm-transactions-bottom-buttons">
          <GHref onClick={discard} className="tm-button-bordered color-brown">{t('common:discard')}</GHref>
          <GHref onClick={confirm} className="confirm">{t('common:confirm')}</GHref>
        </div>
      </aside>
    )
  )
}

export default ViewOptions
