import { dateToDMY } from 'mp-structure/libs/utils'
import useColumnPresentation from 'mp-common/hooks/useColumnPresentation'
import { useMemo } from 'react'

const ColumnDeadlineForOffers = ({ header, tender }) => {
  const { columns } = header || {}
  const { getVisibleFields } = useColumnPresentation()

  const visibleFields = useMemo(() => getVisibleFields(columns), [JSON.stringify(columns)])

  if (!header?.visible) {
    return null
  }

  return (
    <>
      {visibleFields?.deadline_of_offers
        ? tender?.period?.offerDeadline ? dateToDMY(new Date(tender?.period?.offerDeadline)) : '-'
        : '-'}
    </>
  )
}

export default ColumnDeadlineForOffers
