import React from 'react'
import { ColumnType, HeaderType } from 'mp-common/components/table/TableHeader'
import useTranslation from 'next-translate/useTranslation'
import CheckBoxInput from '../../common/components/form/inputs/CheckBoxInput'

type Props = {
  column: HeaderType,
  toggleColumnView: (column: HeaderType) => void
}

const ViewOptionHeader = (props: Props) => {
  const { t } = useTranslation()
  const { column, toggleColumnView } = props

  return (
    <div className="option-row" key={'column_' + column.key}>
      <div className="name">{t(column?.title)}</div>
      <div className="checkbox">
        <CheckBoxInput
          className="form-checkbox"
          form={column}
          name="visible"
          onChange={() => toggleColumnView(column)}
        />
      </div>
    </div>
  )
}

export default ViewOptionHeader
