type Props = {
  children: JSX.Element | JSX.Element[]
}

const GeneralLayoutMain = (props:Props) => {
  const { children } = props

  return (
    <main className="tm-main">
      {children}
    </main>
  )
}

export default GeneralLayoutMain
