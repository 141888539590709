import dynamic from 'next/dynamic'
import GImage from '../../../../common/components/form/elements/GImage'

const EllipsisToolTip = dynamic(() => import('ellipsis-tooltip-react-chan'), { ssr: false })

const ColumnCompany = ({ company }) => (
  <td className="tm-dest-detail border-right">
    <div className="tm-dest-grid">
      <GImage
        src={company?.logoThumbnail}
        className="tm-table-img img-flag"
        placeholder="placeholder-company-avatar.jpg"
        width={24}
        height={24}
      />
      <div className="tm-company-details">
        <EllipsisToolTip>{company?.name}</EllipsisToolTip>
      </div>
    </div>
  </td>
)

export default ColumnCompany
