import { MetaType } from '../../types/MetaType'
import Meta from './Meta'

export type HeaderProps = {
  meta ?: MetaType
}
function Header(props : HeaderProps) {
  const { meta } = props

  return (
    <Meta meta={meta} />
  )
}

export default Header
