import { useRouter } from 'next/router'

const useUpdateQueryParams = () => {
  const router = useRouter()
  const { query } = useRouter()

  const setQueryParams = async (newParams) => {
    await router.push(
      {
        pathname: router.pathname,
        query: { ...newParams }
      },
      undefined,
      { shallow: false }
    )
  }

  const updateQueryParams = async (newParams) => {
    await router.push(
      {
        pathname: router.pathname,
        query: { ...newParams }
      },
      undefined,
      { shallow: false }
    )
  }

  const getCurrentFilters = () => {
    const searchParams = query
    if (searchParams) {
      return searchParams
    }
    return {}
  }

  return { setQueryParams, updateQueryParams, getCurrentFilters }
}

export default useUpdateQueryParams
