import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { dispatchScrollToTop } from 'mp-common/stores/data/action'
import { useEffect } from 'react'

const GeneralLayoutScrollToTop = () => {
  const showScrollToTop = useSelector((state: StateType) => state.data.showScrollToTop)
  const dispatch = useDispatch()

  const handleScroll = () => {
    setTimeout(() => {
      if (window) {
        window.scrollTo({ behavior: 'smooth', top: 0 })
        dispatchScrollToTop(false, dispatch)
      }
    }, 300)
  }
  const scrollEvent = () => {
    if ((window.innerHeight + Math.round(window.scrollY)) > window.outerHeight) {
      dispatchScrollToTop(true, dispatch)
    } else {
      dispatchScrollToTop(false, dispatch)
    }
  }

  useEffect(() => {
    document.removeEventListener('scroll', scrollEvent)
    document.addEventListener('scroll', scrollEvent, false)
    return () => {
      document.removeEventListener('scroll', scrollEvent)
    }
  }, [])

  if (!showScrollToTop) {
    return null
  }

  return (
    <div className="tm-scroll-to-top" onClick={handleScroll}>
      <i className="fa fa-solid fa-arrow-up"></i>
    </div>
  )
}

export default GeneralLayoutScrollToTop
