import useTranslation from 'next-translate/useTranslation'
import moment from 'moment'
import { HeaderType } from 'mp-common/components/table/TableHeader'

export const TRUCK_BOARD_ORIGIN = 'truckBoard'
const useColumnPresentation = () => {
  const { t } = useTranslation()

  const parseData = (data: any) => {
    // check if the data is in dateformat by just simply checking string smartly
    // if its in date format parse it into europe date format
    if (typeof data === 'string' && data.length > 10 && data.includes('-') && data.endsWith('Z') && moment(data).isValid()) {
      return moment(data).format('DD/MM/YYYY HH:mm')
    }

    return data
  }

  const getValue = (path: any, obj: any) => {
    if (path.indexOf('.') > -1) {
      return parseData(path.split('.').reduce((p:any, c:any) => (p && p[c]) || null, obj))
    }

    return parseData(obj[path])
  }

  const formatColumnValue = (column: HeaderType, truck) => {
    if (column?.isDate) {
      return getValue(column.value, truck) ? moment(new Date(getValue(column.value, truck))).format('DD/MM/YYYY') : '-'
    }
    if (column?.isBoolean) {
      const value = getValue(column.value, truck) ? column?.title : null

      return column.handler ? column.handler(value) : t(value)
    }
    if (column.unit && getValue(column.value, truck)) {
      return getValue(column.value, truck) + t('common:' + column.unit)
    }

    if (column.options) {
      return t(column.options + getValue(column.value, truck))
    }

    const value = getValue(column.value, truck) || null

    if (Array.isArray(value)) {
      if (value?.length > 0) {
        return value?.map((v) => v.value)?.join(', ')
      }
      return '-'
    }

    return column.handler ? column.handler(value) : value
  }

  const getVisibleFields = (columns) => columns?.reduce((acc, obj) => {
    acc[obj.key] = obj.visible
    return acc
  }, {})

  return { getValue, formatColumnValue, getVisibleFields }
}

export default useColumnPresentation
