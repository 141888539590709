import React from 'react'
import dynamic from 'next/dynamic'
import { dateToDM } from 'mp-structure/libs/utils'
import get from 'lodash/get'
import GImage from '../../../../common/components/form/elements/GImage'

const EllipsisToolTip = dynamic(() => import('ellipsis-tooltip-react-chan'), { ssr: false })

const ColumnPickup = ({ pickup, selectAndShowQuickView, flagURL = null }) => (
  <td className="tm-dest-detail">
    <div className="tm-dest-grid align-center">
      {flagURL && get(pickup, 'location.countryCode') && (
        <GImage
          src={flagURL + get(pickup, 'location.countryCode') + '.png'}
          className="tm-table-img img-flag"
          alt=""
          width={24}
          height={24}
        />
      )}
      <div className="overflow-hidden">
        <div
          onClick={selectAndShowQuickView}
          className="tm-dest-name cursor-pointer"
        >
          <EllipsisToolTip>{pickup?.location?.label}</EllipsisToolTip>
        </div>
        {pickup?.firstDate
          ? (
            <div className="tm-dest-date">
              <GImage
                src="icon-calender.svg"
                className="tm-table-img"
                width={16}
                height={16}
              />
              {dateToDM(pickup?.firstDate)}-{dateToDM(pickup?.lastDate)}
            </div>
          ) : null}
      </div>
    </div>
  </td>
)

export default ColumnPickup
