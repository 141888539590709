import TableHeader, { HeaderType } from './TableHeader'

interface Props {
  scrollFunction: (props: any) => void
  headerList: HeaderType[],
  isTruckBoard?: boolean
}

function ScrollableTableHeader(props: Props) {
  const { scrollFunction, headerList, isTruckBoard } = props

  return (
    <th colSpan={3} className="tm-extra-info-column headings no-border">
      <div className="tm-extra-columns-outer">
        <a
          href="#"
          className="tm-table-left"
          id="left-button"
          onClick={() => {
            scrollFunction('left')
          }}
        >
          <svg
            width={9}
            height={16}
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.33208 0.918015C9.72261 1.30854 9.72261 1.9417 9.33209 2.33223L2.6642 9.00012L9.33209 15.668C9.72261 16.0585 9.72261 16.6917 9.33208 17.0822C8.94156 17.4728 8.30839 17.4728 7.91787 17.0822L0.896432 10.0608C0.310646 9.475 0.310645 8.52525 0.896431 7.93946L7.91787 0.918016C8.30839 0.527491 8.94156 0.527491 9.33208 0.918015Z"
              fill="#000"
            />
          </svg>
        </a>
        <div className="tm-table-extra-columns-outer">
          <table className="tm-table-transactions tm-extra-columns">
            <tbody>
              <tr>
                {headerList.map((header) => (header.visible && <TableHeader header={header} key={header?.key || header.title || header.icon} isTruckBoard={isTruckBoard} />))}
              </tr>
            </tbody>
          </table>
        </div>
        <a
          href="#"
          className="tm-table-right"
          id="right-button"
          onClick={() => {
            scrollFunction('right')
          }}
        >
          <svg
            width={9}
            height={16}
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.667915 0.918015C0.277391 1.30854 0.27739 1.9417 0.667914 2.33223L7.3358 9.00012L0.667914 15.668C0.27739 16.0585 0.277391 16.6917 0.667915 17.0822C1.05844 17.4728 1.69161 17.4728 2.08213 17.0822L9.10357 10.0608C9.68935 9.475 9.68935 8.52525 9.10357 7.93946L2.08213 0.918016C1.69161 0.527491 1.05844 0.527491 0.667915 0.918015Z"
              fill="#000"
            />
          </svg>
        </a>
      </div>
    </th>
  )
}

export default ScrollableTableHeader
