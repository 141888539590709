import useColumnPresentation from 'mp-common/hooks/useColumnPresentation'
import { getValue } from 'mp-tender/components/board/TenderRow'

const ColumnTruckInformation = ({ header, row }) => {
  const { formatColumnValue } = useColumnPresentation()

  if (!header?.visible) {
    return null
  }

  const nonEmptyColumns = header?.columns?.filter((c) => c?.visible && getValue(c.value, row))
  const nonEmptyColumnCount = nonEmptyColumns.length

  return (
    <>
      {nonEmptyColumnCount > 0
        ? nonEmptyColumns.map((column, index) => (
          formatColumnValue(column, row)
            ? (formatColumnValue(column, row) + (nonEmptyColumnCount > 1 && index < nonEmptyColumnCount - 1 ? ' - ' : ''))
            : ''
        ))
        : '-'}
    </>
  )
}

export default ColumnTruckInformation
