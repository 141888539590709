import { useCallback, useMemo } from 'react'
import { apiGet, apiPost } from 'mp-structure/libs/api'
import { useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { useToast } from 'mp-common/hooks/useToast'
import get from 'lodash/get'

export function removeApiV2(url) {
  const startIndex = url.indexOf('/api/v2')
  if (startIndex !== -1) {
    return url.slice(startIndex + 7)
  }
  return url
}

const useViewOptions = () => {
  const { toastSuccess } = useToast()
  const user = useSelector((state: StateType) => state.auth.user)

  const userOptions = useMemo(() => (
    get(user, 'data.UIStorage')
  ), [user])

  const getViewOptions = useCallback(async (boardName) => {
    const jsonUrl = get(userOptions, boardName)
    if (jsonUrl) {
      const response = await apiGet(removeApiV2(get(userOptions, boardName)))
      if (response) {
        return response
      }
    }
  }, [userOptions])

  const updateViewOptions = async (options: string, boardName: string) => {
    const { data } = await apiPost('uistorage/update', {
      type: boardName,
      data: options
    })
    if (!data?.status?.includes('ok')) {
      toastSuccess('common:an_error_occurred')
    }
  }

  return { getViewOptions, updateViewOptions }
}
export default useViewOptions
