import { dateToDMY } from 'mp-structure/libs/utils'
import useColumnPresentation from 'mp-common/hooks/useColumnPresentation'
import { useMemo } from 'react'

const ColumnPublicationDate = ({ header, row }) => {
  const { columns } = header || {}
  const { getVisibleFields } = useColumnPresentation()

  const visibleFields = useMemo(() => getVisibleFields(columns), [JSON.stringify(columns)])

  if (!header?.visible) {
    return null
  }

  return (
    <>
      {visibleFields?.publication_date
        ? row?.publicationDate ? dateToDMY(new Date(row?.publicationDate)) : '-'
        : '-'}
    </>
  )
}

export default ColumnPublicationDate
