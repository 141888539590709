'use client'

import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'

type Props = {
  user?: any,
  company?: any
}

const useMixPanel = (props: Props) => {
  const { user, company } = props
  const [initialized, setInitialized] = useState(false)

  const trackEvent = (event, properties = {}) => {
    if (mixpanel && typeof window !== 'undefined') {
      mixpanel.track(event, properties)
    }
  }

  const trackForm = (event, properties = {}, identity) => {
    if (mixpanel && typeof window !== 'undefined') {
      mixpanel.reset()
      mixpanel.identify(identity)
      if (company?.email && initialized && typeof window.localStorage !== 'undefined') {
        mixpanel.people.set({
          $name: 'Onboarding Company: ' + company.companyName,
          $email: company.email,
          $last_login: new Date(),
          $custom_properties: {
            vat_id: company.vatId,
            phone_number: company?.phoneNumber
          }
        })
      }
      mixpanel.track(event, properties)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      mixpanel.init(process.env.MIX_PANEL_TOKEN, { debug: true, track_pageview: process.env.NODE_ENV === 'production', persistence: 'localStorage' })
      setInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (user?.id && initialized && typeof window.localStorage !== 'undefined') {
      mixpanel.reset()
      mixpanel.identify('FEX' + user.email)
      mixpanel.people.set({
        $name: user.firstName + ' ' + user.lastName,
        $email: user.email,
        $last_login: new Date(),
        $custom_properties: {
          countryCode: user.countryCode,
          language: user.language,
          phoneNumber: user.phoneNumber
        }
      })
    } else if (!user && !company && initialized && typeof window.localStorage !== 'undefined') {
      mixpanel.reset()
    }
  }, [user?.id, initialized])

  return {
    trackEvent,
    trackForm
  }
}

export default useMixPanel
