import { useCallback, useState } from 'react'
import useFreightBoardFilters from 'mp-freight/hooks/useFreightBoardFilters'
import useTranslation from 'next-translate/useTranslation'
import { GImage } from '../form/elements'

export type ColumnType = {
  title?: string;
  sortable: boolean;
  key?: string;
  icon?: string;
  visible: boolean;
  className?: string;
  value?: string;
  colSpan? : number;
  isDate?: boolean;
  isBoolean?: boolean;
  unit?: string;
  options?: any
}

export type HeaderType = {
  index?: number,
  category?: string,
  draggable?: boolean,
  title?: string;
  scrollable?: boolean;
  visible: boolean;
  columns?: HeaderType[]
  sortable?: boolean;
  icon?: string;
  key?: string;
  className?: string;
  handler ?: (value: any) => any;
  colSpan? : number;
  value?: string;
  isDate?: boolean;
  isBoolean?: boolean;
  unit?: string;
  options?: any
}

interface TableProps {
  header: HeaderType
  isTruckBoard?: boolean
}

function TableHeader(props: TableProps) {
  const { isTruckBoard } = props
  const { sortable, colSpan, key, title, className, icon } = props.header
  const { t } = useTranslation()
  const { applyFilter, form, getCurrentFilters } = useFreightBoardFilters()
  const currentFilters:any = getCurrentFilters()
  const [sortDirection, setSortDirection] = useState(currentFilters?.sort === key ? currentFilters?.sortOrder : undefined)

  const sortBy = useCallback((key: string, order: string) => {
    setSortDirection(order)
    let filters = { ...form } as Record<string, any>
    if (isTruckBoard) {
      filters = { ...filters, sort: key, sortOrder: order }
    } else {
      filters = { ...filters, orderBy: { [key]: order } }
    }
    applyFilter(filters)
  }, [isTruckBoard, form])

  return (
    <th
      colSpan={colSpan}
      className={(sortable ? 'cursor-pointer ' : '') + (className || '')}
      onClick={() => (sortable ? sortBy(key, sortDirection === 'asc' ? 'desc' : 'asc') : null)}
    >
      <a>
        {icon
          ? (
            <GImage
              src={icon}
              alt="stops"
              width={20}
              height={20}
            />
          )
          : <span>{t(title)}</span>}
        {sortable && (
          <div className="tm-tbl-head-filter-icon">
            <GImage
              src="icon-filter-up.svg"
              className={(currentFilters?.sortOrder === 'asc' && currentFilters?.sort === key) ? 'selected' : ''}
              alt="Asc"
            />
            <GImage src="icon-filter-down.svg" alt="Desc" />
          </div>
        )}
      </a>
    </th>
  )
}

export default TableHeader
