import { Channel } from 'pusher-js'
import { useCallback, useEffect, useRef } from 'react'
import { pusherClient } from '../../../pages/_app'

type Props = {
  channels: string[]
}

const usePusher = ({ channels } : Props) => {
  const connectedChannels = useRef<Channel[]>([])
  const connectedEvent = useRef([])

  useEffect(() => {
    channels.forEach(channel => {
      connectedChannels.current.push(pusherClient.subscribe(channel))
    })

    return () => {
      connectedChannels.current.forEach(channel => {
        pusherClient.unsubscribe(channel.name)
      })
    }
  }, [channels])

  const connectEvent = useCallback((event, callback) => {
    for (const connectedChannel of connectedChannels.current) {
      connectedChannel.bind(event, callback)
      connectedEvent.current.push(event)
    }
  }, [])

  const disconnectEvent = useCallback((event) => {
    for (const connectedChannel of connectedChannels.current) {
      connectedChannel.unbind(event)
      connectedEvent.current = connectedEvent.current.filter((e) => e !== event)
    }
  }, [])

  useEffect(() => {
    pusherClient.connection.bind('error', (err) => {
      if (err?.data?.code === 4004) {
        throw new Error('WebSocket connection failed because of event error')
      }
    })
    return () => {
      for (const event of connectedEvent.current) {
        pusherClient.unsubscribe(event)
      }
      pusherClient.connection.unbind('error')
    }
  }, [])

  return { connectEvent, disconnectEvent, client: pusherClient }
}
export default usePusher
