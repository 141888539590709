import Navbar from 'mp-fex/components/Navbar'

type Props = {
  children: JSX.Element | JSX.Element[]
  page: string,
  className?: string
}

const MarketplaceWrapper = (props:Props) => {
  const { children, className, page } = props

  return (
    <section className={className}>
      <Navbar page={page} />
      {children}
    </section>
  )
}

export default MarketplaceWrapper
