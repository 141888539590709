import { HeaderType } from 'mp-common/components/table/TableHeader'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { TenderType } from 'mp-tender/types/TenderType'
import useSession from 'mp-auth/hooks/useSession'
import { useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { domainURL } from 'mp-structure/libs/api'
import ColumnPickup from 'mp-fex/components/table/columns/ColumnPickup'
import ColumnKmStops from 'mp-fex/components/table/columns/ColumnKmStops'
import ColumnDelivery from 'mp-fex/components/table/columns/ColumnDelivery'
import ColumnCompany from 'mp-fex/components/table/columns/ColumnCompany'
import ColumnPrice from 'mp-fex/components/table/columns/ColumnPrice'
import ColumnMiniTenderInfo from 'mp-tender/components/board/ColumnMiniTenderInfo'
import ColumnDeadlineForOffers from 'mp-tender/components/board/ColumnDeadlineForOffers'
import ColumnFreightType from 'mp-fex/components/table/columns/ColumnFreightType'
import ColumnFreightInformation from 'mp-fex/components/table/columns/ColumnFreightInformation'
import ColumnTruckInformation from 'mp-fex/components/table/columns/ColumnTruckInformation'
import ColumnSpecialRequirements from 'mp-fex/components/table/columns/ColumnSpecialRequirements'
import ColumnPublicationDate from 'mp-fex/components/table/columns/ColumnPublicationDate'
import {
  HEADER_FREIGHT_INFORMATION, HEADER_FREIGHT_TYPE, HEADER_MINI_TENDER_INFO, HEADER_OFFER_DEADLINE,
  HEADER_PUBLICATION_DATE,
  HEADER_SPECIAL_REQS, HEADER_TRUCK_INFORMATION
} from 'mp-common/hooks/useBoardTable'
import GImage from '../../../common/components/form/elements/GImage'
import GHref from '../../../common/components/form/elements/GHref'

interface Props {
  tender?: TenderType
  headers: HeaderType[]
  showScroll: boolean
  selectAndShowQuickView: any
  removeTender?: (id: any, loading_location: string, unloading_location: string) => Promise<void>
  isRemoveConfirmed?: boolean
  showRowMenu?: string
  setShowRowMenu?: Dispatch<SetStateAction<string>>
  loadingDeleteTender?: boolean
}
export const getValue = (path: any, obj: any) => {
  if (path.indexOf('.') > -1) {
    return path.split('.').reduce((p:any, c:any) => (p && p[c]) || null, obj)
  }
  return obj[path]
}

const TenderRow = (props: Props): JSX.Element => {
  const {
    headers,
    showScroll,
    selectAndShowQuickView,
    tender,
    removeTender,
    isRemoveConfirmed,
    showRowMenu,
    setShowRowMenu,
    loadingDeleteTender
  } = props
  const { t } = useTranslation()
  const { isCarrier } = useSession()
  const countryFlagEndpoint = useSelector((state: StateType) => state.data.countryFlagEndpoint)
  const flagURL = useMemo(() => (domainURL + countryFlagEndpoint), [domainURL, countryFlagEndpoint])

  const unreadClass = tender.isNew ? ' new-load' : ''

  const isAnyOperationAllowed = useMemo(() => !isCarrier && tender?.isEditable, [tender?.isEditable, isCarrier])

  const getHeaders = (h) => {
    if (h?.category === HEADER_MINI_TENDER_INFO) {
      return <ColumnMiniTenderInfo header={h} tender={tender} />
    }
    if (h?.category === HEADER_OFFER_DEADLINE) {
      return <ColumnDeadlineForOffers header={h} tender={tender} />
    }
    if (h?.category === HEADER_FREIGHT_TYPE) {
      return <ColumnFreightType header={h} row={tender} name="shipmentType.value" />
    }
    if (h?.category === HEADER_FREIGHT_INFORMATION) {
      return <ColumnFreightInformation header={h} row={tender} />
    }
    if (h?.category === HEADER_PUBLICATION_DATE) {
      return <ColumnPublicationDate header={h} row={tender} />
    }
    if (h?.category === HEADER_TRUCK_INFORMATION) {
      return <ColumnTruckInformation header={h} row={tender} />
    }
    if (h?.category === HEADER_SPECIAL_REQS) {
      return <ColumnSpecialRequirements header={h} row={tender} />
    }

    return null
  }

  return (
    <tr className="content">
      <td className={`col-checkbox${unreadClass}`} />
      <ColumnPickup
        pickup={tender?.firstPickup}
        selectAndShowQuickView={() => selectAndShowQuickView(tender)}
        flagURL={flagURL}
      />
      <ColumnKmStops href={tender.mapsLink} row={tender} />
      <ColumnDelivery
        delivery={tender?.lastDelivery}
        selectAndShowQuickView={() => selectAndShowQuickView(tender)}
        flagURL={flagURL}
      />
      <ColumnCompany company={tender?.company} />
      <ColumnPrice row={tender} />
      {showScroll && (
        <td
          colSpan={3}
          className="tm-extra-info-column inner-content p-0"
        >
          <div className="tm-extra-columns-outer">
            <div className="tm-table-left" />
            <div className="tm-table-extra-columns-outer">
              <table className="tm-table-transactions tm-extra-columns">
                <tbody>
                  <tr>
                    {headers?.map((h) => h?.category !== 'constants' && h?.visible && (
                      <td key={h?.category}>
                        {getHeaders(h)}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="tm-table-right" />
          </div>
        </td>
      )}
      <td className={`tm-quote${!isAnyOperationAllowed ? ' tm-quote-expanded' : ''}`} colSpan={!isAnyOperationAllowed ? 2 : 1}>
        <GHref
          onClick={() => selectAndShowQuickView(tender)}
          className={`tm-table-quote-btn tm-open-transaction-sidebar ${!isAnyOperationAllowed ? ' span-2' : ''}`}
        >
          <GImage src="icon-quote.svg" width="24" height="24" />
        </GHref>
      </td>
      {isAnyOperationAllowed && (
        <td className="col-checkbox more pos-rel">
          <>
            <GHref
              className="tm-table-trans-more-btn"
              onClick={() => setShowRowMenu(tender.id)}
            >
              <GImage src="icon-options.svg" />
            </GHref>
            {showRowMenu === tender.id && (
              <div className="tm-trans-more-buttons opened">
                <GHref
                  className="tm-button-bordered color-brown"
                  href={'/fex/tender-board/edit/' + tender.id}
                >
                  <GImage
                    src="icon-edit.svg"
                    width={16}
                    height={16}
                  />
                  {t('table:edit_tender')}
                </GHref>
                <GHref
                  className="tm-button-bordered color-brown"
                  onClick={() => removeTender(tender.id, tender?.firstPickup?.location?.label, tender?.lastDelivery?.location?.label)}
                  submitting={loadingDeleteTender}
                >
                  <GImage
                    src="icon-trash.svg"
                    width={16}
                    height={16}
                  />
                  {isRemoveConfirmed ? t('common:confirm') : t('table:remove_from_board')}
                </GHref>
                <GHref className="tm-trans-more-buttons-close" onClick={() => setShowRowMenu('')}>
                  <GImage
                    src="icon-close.svg"
                    width={20}
                    height={20}
                  />
                </GHref>
              </div>
            )}
          </>
        </td>
      )}
    </tr>
  )
}

export default TenderRow
