import { dateToDMY } from 'mp-structure/libs/utils'
import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'
import useColumnPresentation from 'mp-common/hooks/useColumnPresentation'

const ColumnMiniTenderInfo = ({ header, tender }) => {
  const { t } = useTranslation('table')
  const { columns } = header || {}
  const { getVisibleFields } = useColumnPresentation()

  const visibleFields = useMemo(() => getVisibleFields(columns), [JSON.stringify(columns)])

  if (!header?.visible) {
    return null
  }

  return (
    <>
      {visibleFields.mini_tender_info
        ? (
          <>
            {tender?.period?.startsAt
              ? `${dateToDMY(new Date(tender?.period.startsAt)) + (tender?.period.endsAt ? ` - ${dateToDMY(new Date(tender?.period.endsAt))}` : '')}`
              : ''}
            <br />
            {t('total')}{': '}{tender?.periodFreightTotal || '-'}
            {tender?.period?.interval
              ? `(${t(`x_per_${tender?.period?.interval}`, { x: tender?.period?.count || '-' })})`
              : ''}
          </>
        )
        : '-'}
    </>
  )
}

export default ColumnMiniTenderInfo
