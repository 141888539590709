import { useState } from 'react'
import { serviceInstance } from 'mp-structure/libs/api'
import { freightInstance } from 'mp-structure/libs/freightApi'

const useMarkAsRead = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string>()

  const markTruckAsRead = async (id: string) => {
    setLoading(true)
    try {
      await serviceInstance.post('api/v2/Trucks/MarkAsRead/' + id)
      setSuccess(true)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const markShipmentAsRead = async (id: string) => {
    setLoading(true)
    try {
      await freightInstance.post(`shipment/${id}/read`)
      setSuccess(true)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const markTenderAsRead = async (id: string) => {
    setLoading(true)
    try {
      await freightInstance.post(`tender/${id}/read`)
      setSuccess(true)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  return { markTruckAsRead, markShipmentAsRead, markTenderAsRead, loading, success, error }
}
export default useMarkAsRead
