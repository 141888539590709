import useColumnPresentation from 'mp-common/hooks/useColumnPresentation'
import { getValue } from 'mp-tender/components/board/TenderRow'
import { HeaderType } from 'mp-common/components/table/TableHeader'

const ColumnSpecialRequirements = ({ header, row }) => {
  const { columns } = header || {}
  const { formatColumnValue } = useColumnPresentation()

  if (!header?.visible) {
    return null
  }

  const nonEmptyColumns = columns?.filter((c: HeaderType) => c?.visible && (c.handler ? !!c.handler(getValue(c.value, row)) : getValue(c.value, row)))
  const nonEmptyColumnCount = nonEmptyColumns.length

  return (
    <>
      {nonEmptyColumnCount > 0
        ? nonEmptyColumns.map((column, index) => (
          formatColumnValue(column, row)
            ? (formatColumnValue(column, row) + (nonEmptyColumnCount > 1 && index < nonEmptyColumnCount - 1 ? ', ' : ''))
            : ''
        ))
        : '-'}
    </>
  )
}

export default ColumnSpecialRequirements
