import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import GHref from '../../../../common/components/form/elements/GHref'

const ColumnKmStops = ({ row, href = '' }) => {
  const { t } = useTranslation()

  return (
    <td className="col-km-w-stops" colSpan={2}>
      <GHref
        href={href}
        target="_blank"
        className="col-km-w-stops-columns"
        rel="noreferrer"
      >
        <div className="column">
          <div className="fw-600">{Math.floor(row.totalDistance)}</div>
          <div className="mt-2">km</div>
        </div>
        <div className="column">
          <div className="fw-600">{row.totalStopsCount}</div>
          <div className="mt-2">{t('leads:stops')}</div>
        </div>
      </GHref>
    </td>
  )
}

export default ColumnKmStops
