import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import GLoading from 'mp-common/components/GLoading'
import useTranslation from 'next-translate/useTranslation'

type Props ={
  fetchItems: (params?: any) => Promise<void>,
  hasMore: boolean,
  loading: boolean,
  children: ReactNode | ReactElement | ReactElement[]
}
const InfiniteScroll = (props: Props) => {
  const { fetchItems, children, hasMore, loading } = props
  const { t } = useTranslation()

  const fetchData = useCallback(async () => {
    if (loading || !hasMore) {
      return
    }

    await fetchItems()
  }, [loading, hasMore])

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement

      if (scrollTop + clientHeight >= scrollHeight - 20) {
        fetchData()
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [fetchData])

  return (
    <>
      {children}
      {!loading && !hasMore && <div className="tm-trans-scroll-msg">{t('table:scroll_done')}</div>}
      {!loading && hasMore && <div className="tm-trans-scroll-msg">{t('table:scroll_more')}</div>}
      {loading && <GLoading size={36} customLoadingClassName="h-auto m-10" />}
    </>
  )
}

export default InfiniteScroll
